import { render, staticRenderFns } from "./LayoutWithSubmenu.vue?vue&type=template&id=3671ec60&scoped=true&"
import script from "./LayoutWithSubmenu.vue?vue&type=script&lang=ts&"
export * from "./LayoutWithSubmenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3671ec60",
  null
  
)

export default component.exports