



























































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { MenuEntry } from '@/store/types'
import MainMenuDisabled from '@/store/common/MainMenuDisabled'
import { hasRight } from '@/utils/rights'

@Component({

})
/*
@group MENUS
The submenu is displayed on pages using the LayoutWithSubmenu template.
It renders a submenu left to the main site content when the prop is filled with menuEntries.
*/
export default class SubMenuDesktop extends Vue {
  activeChain: HTMLElement[] = []
  levelIdBase = 'main-menu-entry-'
  @State('currentPage') currentPage: number[]

  // sub menu entries
  @Prop()
  menuEntries: MenuEntry

  toggleCollapse (id: string): void {
    this.$root.$emit('bv::toggle::collapse', id)
  }

  get pageIndices (): number[] {
    const pages = [...this.currentPage]
    pages.splice(0, 2)
    return pages
  }

  get doNotShowInMenu (): string {
    return MainMenuDisabled.DO_NOT_SHOW_IN_MENU
  }

  hasRight (rights: string[]): boolean {
    return hasRight(...rights)
  }

  setActiveLevel (el: HTMLElement, level: number, toggle: boolean, navigate: boolean, route: string | null): void {
    /* TODO: Komponente so überarbeiten, dass Öffnen und Schließen von Unter-Untermenüs auch gewährleistet wird, wenn
    *  eine Seite nicht über dieses Menü geöffnet wird
    */
    /*
    if (navigate) {
      this.lastActiveChain.forEach(link => {
        link.classList.remove('active')
        for (let i = 0; i < link.children.length; i++) {
          link.children[i].classList.remove('active')
        }
      })
    }
    */

    let close = false
    if (this.activeChain.length - 1 >= level) {
      const count = this.activeChain.length
      const deleted = this.activeChain.splice(level - 1, level + (count - level))

      deleted.forEach((oldElement) => {
        if (oldElement.id === el.id && toggle) {
          close = true
        }

        // close deprecated panels
        const splitParts = oldElement.id.split('-')
        const length = splitParts.length
        this.toggleCollapse('level' + splitParts[0] + '-collapse-' + splitParts[length - 1])

        const collapseIndicator = oldElement.getElementsByClassName('collapse-indicator')
        if (collapseIndicator !== null) {
          const item = collapseIndicator.item(0)

          if (item !== null) {
            item.innerHTML = 'arrow_drop_down'
          }
        }
      })
    }

    if (!close) {
      this.activeChain.push(el)

      // open new accordion
      const splitParts = el.id.split('-')
      const length = splitParts.length
      this.toggleCollapse('level' + (level).toString() + '-collapse-' + splitParts[length - 1])

      const collapseIndicator = el.getElementsByClassName('collapse-indicator')
      if (collapseIndicator !== null) {
        const item = collapseIndicator.item(0)

        if (item !== null) {
          item.innerHTML = 'arrow_drop_up'
        }
      }
    }

    if (navigate && route !== null) {
      /*
      this.lastActiveChain = []
      this.activeChain.forEach(link => {
        this.lastActiveChain.push(link)
        if (link.id === el.id) {
          link.classList.add('active')
        }

        for (let i = 0; i < link.children.length; i++) {
          link.children[i].classList.add('active')
        }
      })
      */

      // this.toggleCollapse('main-menu-mobile')
      this.$router.push({ name: route }).catch(error => {
        if (!error.toString().indexOf('redundant navigation')) {
          throw error
        }
      })
    }
  }
}
