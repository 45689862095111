




















import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import SubMenuDesktop from '@/components/menus/SubMenuDesktop.vue'
import { Getter, State } from 'vuex-class'
import { MenuEntry } from '@/store/types'

@Component({
  components: { SubMenuDesktop }
})
export default class LayoutWithSubmenu extends Vue {
  @Getter('getCurrentPage') getCurrentPage: number[]
  @Getter('getMenuEntries') allMenuEntries: MenuEntry[]
  @State('showDesktopVersion') showDesktopVersion: boolean
  menuEntries: object = { children: [] }

  @Watch('getCurrentPage')
  onCurrentPageChanged (): void {
    this.menuEntries = this.getMenuEntries()
  }

  @Watch('allMenuEntries')
  onMenuEntriesChanged (): void {
    this.menuEntries = this.getMenuEntries()
  }

  getMenuEntries (): MenuEntry {
    if (this.getCurrentPage.length > 2) {
      const lastIndex = this.getCurrentPage.length - 1
      let mainEntry: MenuEntry = { name: '', route: '', disabledIndicator: '', children: this.allMenuEntries }
      for (let level = 0; level < lastIndex; level++) {
        if (this.getCurrentPage[level] !== -1) {
          mainEntry = mainEntry.children[this.getCurrentPage[level]]
        }
      }
      return mainEntry
    } else {
      return { name: '', route: '', disabledIndicator: '', children: [] }
    }
  }

  mounted (): void {
    this.menuEntries = this.getMenuEntries()
  }
}
